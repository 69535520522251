.paymentSection {
    
    .paymentSidebar {
        box-shadow: 0px 4px 40px 0px #70707030;
        border-radius: 10px;
        padding: 25px 25px;

        .paymentUl {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: flex;
                justify-content: space-between;
                padding: 15px 0px;
                border-bottom-style: dotted;
                border-color: #3d4755;
                border-bottom-width: 1.5px;

                &.totalPayment {
                    font-size: 18px;
                    font-weight: 500;
                }

                &:last-child {
                    border: none;
                }

                .applyBtn {
                    background: var(--Main);
                    padding: 2px 7px;
                    font-size: 12px;
                    margin-left: 5px;
                    position: relative;
                    top: -2px;
                }
            }
        }

        .paymentBtn {
            background-color: var(--Main);
            width: 100%;
            padding: 10px 15px;
            font-weight: 500;
            margin-top: 15px;
        }
    }
}