
.couponCode {
  border: none;
  background: #fff;
  display: flex;
  width: 100%;
  box-shadow: 0px 4px 40px 0px #70707030;
  border-radius: 10px;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px 15px;
  border: 2px solid #fff;


  .couponBody {
      width: 100%;
      text-align: left;

      h5 {
          margin-bottom: 0px;
          font-size: 16px;
      }
      p {
        margin: 0px;
        font-size: 14px;
        margin-top: 3px;

        span {
            font-style: italic;
            font-weight: 500;
            color:var(--Main);
        }
      }
  }
  .applyBody {

    .applyBtn {
        font-size: 12px;
        background: var(--Main);
        border:2px solid var(--Main);
        padding: 3px 15px;
        color: #222;

        &:focus {
            border:2px solid var(--Main);
            background: #fff;
            color: var(#222);

        }
    }
  }
}