@import "variables.module.scss"; 
@import "fonts.module.scss"; 

body {
  background-color: var(--BodyBackground) !important;
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #222;
  width: 100%;
  height: 100%;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.5px;
  font-family:'Poppins' !important;
}

a {

  &:hover {
    color: var(--Main2);
  }
}

.radio {
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
  
	&:hover input ~ .checkmark {
	    background-color: #f3f1f1;
		border: 1px solid #d8d8d8;
	}
  
    input {
		opacity: 0;
		margin-right:5px;
	  
	    &:checked ~ .checkmark {
		  background-color: var(--Main);
		  border: 1px solid var(--Main);
		}
		&:checked ~ .checkmark:after {
		  display: block;
		}
	}
	.checkmark {
		position: absolute;
		top: 5px;
		left: 0;
		height: 17px;
		width: 17px;
		background-color: #eee;
		border-radius: 50%;
        border: 1px solid #d8d8d8;

	    &:after {
			content: "";
			position: absolute;
			display: none;
			left: 4px;
			top: 4px;
			width: 7px;
			height: 7px;
			border-radius: 50%;
			background: white;
		}
    }	
}
.checkbox {
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	padding-left: 25px;
  
	&:hover input ~ .checkmark {
	    background-color: #f3f1f1;
		border: 1px solid #d8d8d8;
	}
  
    input {
		opacity: 0;
		margin-right:10px;
		position:absolute;
	  
	    &:checked ~ .checkmark {
		  background-color: var(--Main);
		  border: 1px solid var(--Main);
		}
		&:checked ~ .checkmark:after {
		  display: block;
		}
	}
	.checkmark {
		position: absolute;
		top: 2px;
		left: 0;
		height: 18px;
		width: 18px;
		background-color: #fff;
		border-radius: 3px;
        border: 1px solid #d8d8d8;

	    &:after {
			content: "";
			position: absolute;
			display: none;
		    left: 6px;
			top: 2px;
			width: 5px;
			height: 10px;
			border: solid white;
			border-width: 0 2px 2px 0;
			-webkit-transform: rotate(45deg);
		    -ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
    }	
}
.switch {
	position: relative;
	vertical-align: top;
	width: 100px;
	height: 27px;
	background:#fff;
	cursor: pointer;
	box-sizing:content-box;
}
.switchInput {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	box-sizing:content-box;
}
.switchLabel {
	position: relative;
	display: block;
	height: inherit;
	font-size: 12px;
	background: #eceeef;
	box-sizing:content-box;
	border: 1px solid #e4dcdc;
    overflow: hidden;
	border-radius: 5px;
}
.switchLabel:before, .switchLabel:after {
	position: absolute;
	top: 50%;
	margin-top: -.5em;
	line-height: 1;
	-webkit-transition: inherit;
	-moz-transition: inherit;
	-o-transition: inherit;
	transition: inherit;
	box-sizing:content-box;
}
.switchLabel:before {
	content: attr(data-off);
	right: 11px;
	color: #aaaaaa;
	text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.switchLabel:after {
	content: attr(data-on);
	left: 11px;
	color: #FFFFFF;
	text-shadow: 0 1px rgba(0, 0, 0, 0.2);
	opacity: 0;
}
.switchInput:checked ~ .switchLabel {
	background: #2cd07e;
}
.switchInput:checked ~ .switchLabel:before {
	opacity: 0;
}
.switchInput:checked ~ .switchLabel:after {
	opacity: 1;
}
.switchHandle {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 23px;
    height: 23px;
    background: #d8d8d8;
}
.switchHandle:before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -6px 0 0 -6px;
	width: 12px;
	height: 12px;
	background: #d8d8d8;
	border-radius: 6px;
}
.switchInput:checked ~ .switchHandle {
	left: 74px;
	background:#fff;
}
.switchInput:checked ~ .switchHandle:before {
	background:#fff;
}
 
/* Transition
========================== */
.switchLabel, .switchHandle {
	transition: All 0.3s ease;
	-webkit-transition: All 0.3s ease;
	-moz-transition: All 0.3s ease;
	-o-transition: All 0.3s ease;
}


.title {

  .heading {
    font-size: 36px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 5px;
  }
  p {
    color: #525252;
  }
}

.sectionPadding {
  padding: 80px 0px;
}


.formBox {
  width: 100%;
  margin-bottom: 15px;

  label {
    font-size: 15px;
    margin-bottom: 5px;
  }

  :global(.form-control) {
      background-color: #f8f9f9;
      border: 1px solid #ccc;
      padding: 10px 15px;
      font-size: 15px;
      border-radius: 5px;

      &:focus {
          box-shadow: none;
          border: 1px solid #fbbf15;
      }
      &.removeArrow {
        -moz-appearance: textfield;
        
        &::-webkit-outer-spin-butto,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
  }
  
  :global(.form-select) {
    background-color: #f8f9f9;
    border: 1px solid #ccc;
    padding: 10px 15px;
    font-size: 15px;
    border-radius: 5px;

    &:focus {
        box-shadow: none;
        border: 1px solid #fbbf15;
    }
    &.removeArrow {
      -moz-appearance: textfield;
      
      &::-webkit-outer-spin-butto,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
}
  .eyeIcon {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: #686868;
    cursor: pointer;
  }
  &.iconForm {
    position: relative;

    .icon {
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translate(0%, -50%);
      font-size: 20px;
      width: 40px;
      text-align: center;
      line-height: 41px;
      height: 100%;
      color: #3d4755;
    }

    :global(.form-control) {
      padding-left: 35px;
    }
  }

  .submitBtn {
    min-width: 150px;
      background: var(--Main);
      padding: 8px 15px;
      border-radius: 6px;
      font-weight: 500;
      font-size: 15px;
      border: 2px solid var(--Main);

      &:hover,&:active {
        background: var(--Main);
        border: 2px solid var(--Main);
      }

  }
  .fullWidth {
    width: 100%;
  }
  .submitBtn2 {
    background: #fff;
    min-width: 150px;
    border: 2px solid var(--Main);
    color:#222;
    padding: 8px 15px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 15px;

    &:hover,&:active {
      border: 2px solid var(--Main);
      
      background: var(--Main);
      color: #fff;
    }
  }
}

.headerFix {
  min-height: 80px;
  background-color: #38424f;

}


/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/


@media (max-width: 767px) {

  .pageWrapper {
    margin-left: 0px;
  
    .pageTitle {
      padding: 20px 20px;
      display:block;

        .rowTitleLeft {
          margin-bottom:20px;

          h5 {
            font-size: 30px;
            font-weight: 300;
            margin-bottom: 0px;
            color: #4D4D4D;

            .back {
                color: var(--Main2);
                text-decoration: none;
                margin-right: 20px;
            }
        }
      }
      
      .rowTitleRight {    
        overflow-x: scroll;
        display:flex;
        padding-bottom: 10px;

        :global(.btn) {
          min-width: auto;
        }
      }
      
    }
  
    .pageWrapperInside {
      padding:20px 20px;
  
    }
  }
  
  .sectionPadding {
    padding: 40px 0px;
  }
  .submitBtn {
    min-width: 150px;
      background: var(--Main);
      padding: 8px 15px;
      border-radius: 6px;
      font-weight: 500;
      font-size: 15px;
      border: 2px solid var(--Main);
      margin-bottom: 10px;

      &:hover,&:active {
        background: var(--Main);
        border: 2px solid var(--Main);
      }

  }
  .submitBtn2 {
    background: #fff;
    min-width: 150px;
    border: 2px solid var(--Main);
    color:#222;
    padding: 8px 15px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 10px;

    &:hover,&:active {
      border: 2px solid var(--Main);
      
      background: var(--Main);
      color: #fff;
    }
  }

}

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    
  }
  @media only screen and (max-width: 5520px) and (min-width: 768px) {
    
  }

  @media only screen and (min-width: 1200px) and (max-width: 1279px) {

  }


@media (min-width: 1200px) {


}

  /* =======================================================
  //////////////RESPONSIVE MEDIA QUERY END///////////////
  ======================================================= */