.bookingSection {
  position: relative;
  margin-top: 0px;
  margin-left: 250px;

  .bookingForm {
    background: #fff;
    position: fixed;
    width: 450px;

    .bookingScroll {
      height: calc(100vh - 236px);
      overflow: hidden;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 20px 20px;
    }

    .mobileMapSection {
      display: none;
    }

    .formBox {
      width: 100%;
      margin-bottom: 15px;

      :global(.form-control) {
        background-color: #f8f9f9;
        border: 1px solid #dfe0e2;
        padding: 10px 15px;
        font-size: 15px;
        border-radius: 5px;
        text-transform: uppercase;

        &:focus {
          box-shadow: none;
          border: 1px solid #fbbf15;
        }
      }
      .submitBtn {
        background: var(--Main);
        width: 100%;
        padding: 10px 15px;
        border-radius: 6px;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 15px;
      }
    }
  }

  .mapSection {
    padding-left: 450px;
    padding-right: 0;
    width: 100%;

    .map {
      height: calc(100vh - 80px);
      width: 100%;
      display: block;
    }
  }
}

.bookingType {
  display: flex;
  flex-wrap: wrap;

  .radio {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
    margin-right: 10px;
    font-size: 14px;
    margin-bottom: 15px;

    &:hover input ~ .checkmark {
      background-color: #fff;
      border: 2px solid var(--Main);
    }

    input {
      opacity: 0;
      margin-right: 5px;

      &:checked ~ .checkmark {
        background-color: var(--Main);
        border: 2px solid var(--Main);
      }
      &:checked ~ .checkmark:after {
        display: block;
      }
    }
    .checkmark {
      position: absolute;
      top: 3px;
      left: 0;
      background-color: #fff;
      border: 2px solid var(--Main);
      width: 15px;
      height: 15px;
      text-align: center;
      border-radius: 50px;

      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 2px;
        top: 2px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: white;
      }
    }
  }
}

.btnFix {
  width: 100%;
  border-top: 1px solid #eae8e8;
  margin: 0px;
  padding: 5px 15px 15px 15px;
  background: white;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      justify-content: space-between;
      padding: 12px 0px;
      border-bottom: 1px solid #bdbdbd;

      &:last-child {
        border: none;
      }
    }
  }
}

.taxiList {
  .viewMore {
    text-decoration: underline;
  }
}

.title {
  .heading {
    font-size: 22px;
    font-weight: 600;
  }
  p {
    color: #525252;
    font-size: 14px;
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 991px) {
  .bookingSection {
    margin-top: 61px;

    .bookingForm {
      width: 100%;

      .bookingScroll {
        height: calc(90vh - 150px);
      }

      .mobileMapSection {
        display: block;
        margin-bottom: 5px;

        .map {
          width: 100%;
        }
      }
    }

    .mapSection {
      display: none;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .stepCard {
    .stepArrow {
      width: 200px;
      right: -110px;
    }
    .stepArrow2 {
      width: 200px;
      right: -110px;
    }
  }
}
