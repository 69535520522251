.bookingSection {
    position: relative;

    .bannersection {
        position: relative;

        img {
            width: 100%;
            height: 80px;
            object-fit: cover;
        }

        .bannerContent {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            text-align: center;
            margin-top: 20px;

            h2 {
                font-size: 40px;
                color: #fff;
                text-transform: uppercase;
                font-weight: 600;
                margin-bottom: 0px;
            }
        }
    }
}


.successPage {
    box-shadow: 0px 4px 40px 0px #70707030;
    background-color: #fff;
    border-radius: 15px;
    padding: 40px 40px;
    text-align: center;
     
    .check {
        font-size: 80px;
        color: #179b07;
    }
    h5 {
        font-size: 20px;
        margin-top: 15px;
    }
    .rideBtn {
        background: var(--Main);
        border-color: var(--Main);
        padding: 8px 30px;
        margin-top: 20px;
    }
}





/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width:991px) {

    .bookingSection {

        .bannersection {

            .bannerContent {
                width: 92%;
            }
        }

    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {}