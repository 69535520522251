.selectTaxi {
  border: none;
  background: #fff;
  display: flex;
  width: 100%;
  border-radius: 10px;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px 15px;
  border: 2px solid #f5f5f5;

  // &:focus {
  //   border: 2px solid var(--Main);
  // }
  &.active {
    border: 2px solid var(--Main);
  }

  img {
    width: 70px;
    height: 70px;
    object-fit: contain;
    margin-right: 15px;
    border-radius: 50%;
    background-color: gray;
    padding: 10px;
  }

  

  .taxiTypeBody {
    width: 100%;
    text-align: left;
    padding-left: 20px;

    h5 {
      font-size: 22px;
      font-weight: 600;
      padding: 0;
    }
  
    p {
      margin: 0px;
      line-height: 22px;
      color: #525252;
      font-size: 14px;

      ul{
        margin: 0px;
        padding: 0px;
        display: flex;
        li{
          list-style: none;
          padding-right: 4px;
        }
      }
    }
  }
}
