

.driverRating_sec {
  margin-top: 6px;
  .rating_header {
    display: flex;
    // align-items: center;
    background-color: var(--Main);
    padding: 10px 15px;
    padding-top: 19px;
    h5 {
      margin-left: 10px;
    }
  }

  .rating_card {
    padding: 8px 15px;
    background-color: rgb(44, 44, 44);

    .ratingCardDetil {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      border-bottom: 1px solid #000;
      padding-bottom: 10px;

      img {
        width: 65px;
        height: 65px;
        object-fit: cover;
        margin-right: 15px;
        border-radius: 50%;
        background-color: gray;
        
      }
      h5{
        font-size: 16px;
      }
      p{
        margin: 0;padding: 0;
      }
    }
    .ratingPrice {
      padding: 8px 6px;
      .ratingPrice1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h5 {
          color: #fff;
          font-size: 16px;
        }
        p {
          margin: 0;
          padding: 0;
          color: var(--Main);
        }
      }
    }
  }
  .ratingRange {
    background-color: #222020;
    padding: 10px 15px;

    .range {
      padding: 10px 8px;
      h5 {
        color: var(--Main);
        font-size: 14px;
      }
     
     
    }
  }
  .leaveComment{
    
    background-color: rgb(44, 44, 44);
    padding: 15px 10px;

    input{
      background-color: transparent;
      width: 100%;
      color: #fff;
      height: 50px;
      border: none;
      border-bottom: 1px solid #fff;
      margin-bottom: 4px;
      &:focus{
        box-shadow: none !important;
        outline: none !important;
      
      }
    }
  }
}
