.contentBox {
    border-radius: 15px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    padding: 25px 25px;
    margin-bottom: 30px;
    background: #fff;

    &.countCardBox {
        padding: 30px 25px;

        .staticClick {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            margin-top: 15px;

            li {

                button {
                    background: transparent;
                    border: none;
                    padding: 2px 2px;
                    border-bottom: 3px solid #dfdfdf;
                    font-size: 14px;
                    margin-right: 10px;
                    color: #9c9b9b;

                    &.active , &:focus{
                       border-bottom: 3px solid var(--Main2);
                       color: var(--Main2);
                    }
                }
            }
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #808080;
            margin-bottom: 12px;
        }
        h3 {
            margin: 0;
            font-style: normal;
            font-weight: 500;
            font-size: 30px;
            color: #4D4D4D;
            line-height: 2rem;

            a {
                text-decoration: none;
            }
        }
    }

    .tagsList {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 10px;

            span {
                display: block;
                border: 1px solid var(--Main2);
                padding: 5px 15px;
                border-radius: 8px;
            }
        }
    }
}

.addMoney {
    background: var(--Main);

    &:hover , &:focus{
        background: var(--Main);
    }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {

  
}