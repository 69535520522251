
.couponBox {
    border-style: dotted;
    border-color: #8e8e8e;
    border-width: 1.5px;
    border-radius: 5px;
    padding: 8px;
    margin-bottom: 20px;

    .background {
        background: var(--Main);
        padding: 10px 15px;
        border-radius: 5px;

        small {
            font-size: 12px;
            color: #443e3e;
        }
    }  
    .backgroundExpired {
        background:#fbbf154a;
        padding: 10px 15px;
        border-radius: 5px;

        small {
            font-size: 12px;
            color: #443e3e;
        }
    }

    .printButton {
        background: #fff;

        text-align: center;
        display: inline-block;
        padding: 4px 12px;
        border-style: dotted;
        border-radius: 10%;
        border-color: #3d4755;
        color: #fbbf15;
    margin-top: 2px;
    }
      
    
      

    .code {
        background: #fff;
        text-align: center;
        display: inline-block;
        padding: 8px 20px;
        border-style: dotted;
        border-color: #3d4755;
    }
    .codeDownload{
        background: #fff;

        text-align: center;
        display: inline-block;
        padding: 4px 12px;
        border-style: dotted;
        border-radius: 10%;
        border-color: #3d4755;
    }
    .rideBtn {
        display: inline-block;
        background: #3d4755;
        padding: 6px 15px;
        border: none;
        margin-top: 10px;
        font-size: 13px;
        color: #fff;
    }

    .location {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            position: relative;
            padding-left: 20px;
            font-size: 12px;
            padding-bottom: 12px;

            &::before {
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                background: #000;
                left: 0;
                top: 10px;
                transform: translate(-0%, -50%);
                border-radius: 50px;
            }

            &:first-child {

                &::after {
                    content: '';
                    position: absolute;
                    width: 3px;
                    height: 100%;
                    background: #000;
                    left: 3px;
                    top: 30px;
                    transform: translate(50%, -50%);
                    margin-top: 0px;
                }
            }
        }
    }
}