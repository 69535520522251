.modalCts {
  :global(.modal-dialog) {
    :global(.modal-content) {
      border: none;
      background-color: #f3f4f9;

      :global(.modal-header) {
        padding: 10px 20px;
        background: var(--Main);

        :global(.modal-title) {
          font-size: 20px;
        }

        .closeIcon {
          position: absolute;
          right: 5px;
          top: 5px;
          background: transparent;
          border: none;
          width: 40px;
          height: 40px;
          padding: 5px 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50px;
          font-size: 25px;
        }
      }

      :global(.modal-footer) {
        padding: 8px 12px;
        justify-content: center;

        .submit {
          background: var(--Main);
          color: #222;
          border-color: var(--Main);
        }
      }
    }
  }
}

.searchForm {
  box-shadow: 0px 4px 40px 0px #70707030;
  border: none;
  font-size: 14px;
  margin-bottom: 15px;
  padding: 10px 15px;
  border: 1px solid #fff;

  &:focus {
    box-shadow: 0px 4px 40px 0px #70707030;
    border: 1px solid var(--Main);
  }
}

.paymentTab {
  :global(.nav-pills) {
    margin-bottom: 15px;

    :global(.nav-item) {
      :global(.nav-link) {
        background-color: #fff;
        border: 2px solid var(--Main);
        color: #222;
        min-width: 150px;
        text-align: center;
        margin-right: 15px;

        &:global(.active) {
          background-color: var(--Main);
        }
      }
    }
  }
}
.logoutPopup {
  padding: 20px 20px;

  h3 {
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    width: 100%;
    margin-bottom: 15px;
  }

  :global(.btn) {
    width: 100%;
    color: #000;
    display: block;
    padding: 8px 8px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
    margin-top: 10px;
  }

  .actionBtn {
    background-color: var(--Main);
    border-radius: 6px;
  }

  .btnsAlignments {
    display: flex;

    :global(.btn) {
      margin: 0px 5px;
    }
  }
}

.ratingDetailsM {
  :global(.form-control) {
    background-color: transparent;
    color: #fff;

    border: none;
    border-bottom: 1px solid #fff;
    border-radius: 0px;

    &:focus {
      box-shadow: none;
    }
  }
  :global(.form-label) {
    color: #8c8c8c;
    font-size: 14px;
    padding-left: 10px;
  }
  :global(.form-select) {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #fff;
    border-radius: 0px;
    padding-bottom: 5px;
    color: #fff;

    &:focus {
      box-shadow: none;
    }
    option {
      background: #000 !important;
      color: #fff !important;
    }
  }

  .rating_card {
    background-color: rgb(80, 80, 80);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #000;
    padding: 10px 20px;

    img {
      height: 55px;
      width: 55px;
      border-radius: 50%;
      object-fit: contain;
      background-color: lightgray;
    }

    .rating_cardInside {
      display: flex;
      gap: 10px;
      color: #fff;
      align-items: center;
    }

    h5 {
      color: #fff;
      font-size: 14px;
    }
    p {
      color: #fff;
      margin: 0px;
      font-size: 14px;
    }
  }

  .ratingDetails {
    background-color: rgb(80, 80, 80);
    padding: 10px 20px;

    .detailsCard {
      display: flex;
      justify-content: space-between;

      h5 {
        color: #fff;
        font-size: 14px;
      }
      p {
        font-size: 14px;
        margin: 0;
        color: var(--Main);
      }
    }
    .detailsCardTotal {
      display: flex;
      justify-content: space-between;

      h5 {
        color: #fff;
        font-size: 18px;
      }

      p {
        font-size: 20px;
        margin: 0px;
        color: var(--Main);
      }
    }

    .needHelp {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.receiptReport {
  color: #fff;
  padding: 10px 40px;

  ul {
    margin: 0px;
    padding: 0px;
    li {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 35px;
      span {
      }
    }
  }

  .qrCodeImg {
    display: flex;
    justify-content: center;
    padding: 10px 20px;
    margin-top: 20px;

    img {
      height: 280px;
      width: 100%;
    }
  }
}

.notificationModule {
  padding: 10px 20px;
  color: #fff;

  ul {
    margin: 0px;
    padding: 0px;
    li {
      list-style: none;
      border-bottom: 1px solid #fff;
      padding: 20px 0px;
      h5 {
        font-size: 14px;
      }
      p {
        margin: 0px;
        font-size: 12px;
      }
    }
  }
}
