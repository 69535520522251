.bookingSection {
    position: relative;

    .bannersection {
        position: relative;

        img {
            width: 100%;
            height: 250px;
            object-fit: cover;
        }

        .bannerContent {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            text-align: center;
            margin-top: 20px;

            h2 {
                font-size: 40px;
                color: #fff;
                text-transform: uppercase;
                font-weight: 600;
                margin-bottom: 0px;
            }
        }
    }
}


.loginForm {
    box-shadow: 0px 4px 40px 0px #70707030;
    background-color: #fff;
    border-radius: 15px;
    padding: 40px 40px;

    .contentBody {
        h3 {
            font-size: 23px;
            margin-bottom: 30px;
            font-weight: 600;
            text-align: center;
            color: #232322;
        }


        .forgotPassword {
            text-align: end;
            margin-top: 5px;

            a {
                font-size: 14px;
                color: #757575;
            }
        }

        .signupPoint {
            text-align: center;
            margin-top: 30px;

            p {
                color: #101010;
                margin-bottom: 0px;

                a {
                    color: #fbbf15;
                    text-decoration: underline;
                }
            }
        }

        :global(.nav-pills) {
            margin-bottom: 15px;
            justify-content: center;
        
            :global(.nav-item) {
        
              :global(.nav-link) {
                background-color: #fff;
                border: 2px solid var(--Main);
                color: #222;
                min-width: 150px;
                text-align: center;
                margin:0px 10px;
        
                &:global(.active) {
                  background-color: var(--Main);
                }
              }
            }
          }

    }
}





/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width:991px) {

    .bookingSection {

        .bannersection {

            .bannerContent {
                width: 92%;
            }
        }

    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {}