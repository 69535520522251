@import "variables.module.scss"; 





.calendarDropdown {


  :global(.dropdown-menu) {
    padding: 0px 0px !important;
    overflow: hidden;
  }
}

:global(.dropdown-toggle) {
  
  &::after {
    border: none !important;
    width: 14px;
    height: 7px;
    vertical-align: 1px !important;
    margin-left: 10px !important;
  }
}


.pageWrapper {
  margin-left: 250px;
  .pageTitle {
    z-index: 99;
    background: #fff;
    margin: 0;
    padding: 15px 30px;
    border-bottom: 1px solid #eeeeee;

    .back {
        color: var(--Main2);
        text-decoration: none;
        margin-right: 20px;
    }
       
    .backBtn {
      text-decoration: none;
      color: #808080;
      display: inline-block;
      margin-bottom: 5px;
      font-size: 14px;
    }
    
    .pageTitleRow {
      display:flex;
      justify-content: space-between;
      
    }

    .rowTitleLeft {

        h5 {
          font-size: 24px;
          font-weight: 400;
          margin-bottom: 0px;
          color: #4D4D4D;
       }
    }
    
    .rowTitleRight {
      display:flex;

        .selectDropdown {
          display: inline-block;
              margin-left: 10px;

          :global(.multi-select) {

            :global(.dropdown-container) {
                background-color: #fff;
                border-color: #B3B3B3;
                color: #B3B3B3;
                border-radius: 50px;

                :global(.dropdown-heading-value) {
                  width: 100px;
                  text-align: left;
                  overflow: hidden;
                  -o-text-overflow: ellipsis;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  white-space: break-spaces;
                  word-break: break-all;
                  font-size: 14px;

                  span {
                    display: block;
                  }
                }

                :global(.clear-selected-button) {
                  display:none;
                }
            }

            :global(.dropdown-content) {
              z-index: 99;

              :global(.panel-content) {
                padding:15px 15px;
                border-radius: 10px;
                width: 229px;

                :global(.search) {
                   border:none;
                   margin-bottom: 5px;

                  input {
                    background: #fff;
                    border: 1px solid #dbdbdb;
                    border-radius: 50px;
                    font-size: 12px;
                    padding: 0px 15px;
                  }
                }
                :global(.options) {

                  :global(.select-item)  {
                    padding: 4px 0px;
                    font-weight: 400;
                    color: #4D4D4D;
                    background: #fff !important;

                    :global(.item-renderer) {
                      position: relative;
                      align-items: center;
                      
                        input {
                          width: 18px;
                          height: 18px;
                          position: relative;
                          top: 1px;
                          left: 2px;
                          border: none;
                          border-radius: 14px;
                          margin-right: 10px;
                        }
                    }
                  }

                }
              }
            }

          }


          :global(.css-1s2u09g-control), :global(.css-1pahdxg-control) {
            border: 1px solid #B3B3B3;
            padding: 0px 15px;
            border-radius: 50px;
            box-shadow: none;

            &:hover, &:focus {
              border: 1px solid #B3B3B3;
              box-shadow: none;
            }

            :global(.css-1okebmr-indicatorSeparator) {
              display: none;
            }

            :global(.css-tlfecz-indicatorContainer), :global(.css-1gtu0rj-indicatorContainer) {
              padding: 0px 0px;
            }
          }

        }

        :global(.btn) {
            background-color: #fff;
            border-color: #B3B3B3;
            color: #B3B3B3;
            border-radius: 50px;
            min-width: 80px;
            padding: 8px 25px;
            margin-left: 10px;    
            font-size: 14px;
            white-space: nowrap;

            &.themeBtn {
              background: var(--Main);
              border-color: var(--Main);
              color:#333;
            }

            .icon {
                margin-right: 5px;
                margin-top: -1px;
            }

            &:focus {
                box-shadow: none;
            }
            .iconClose {
              margin-left: 10px;
              margin-top: 1px;
              cursor: pointer;
            }
        }

        :global(.dropdown) {
            display: inline-block;

            :global(.dropdown-menu) {
              padding: 10px 15px;
              border-radius: 10px;
              border: none;
              box-shadow: 0 1px 5px 2px #0000001f;

              ul {
                list-style:none;
                margin:0;
                padding:0;

                li {


                  a {
                    color:#4D4D4D;
                    text-decoration: none;
                    display:block;
                    margin: 8px 0px;
                    font-size:12px;
                  }

                }
              }
            }
        }
    }
  }

  .pageWrapperInside {
    background-color: var(--BodyBackground);
    padding: 30px 30px;

    &.setWidth {
      max-width: 70rem;
      margin: 0 auto;
    }

    :global(.card) {
      background-color: transparent;
      border: none;

      :global(.card-body) {
        padding:0px;
        margin-bottom: 0px;
        background: #fff;
        border-radius: 15px;
      }
    }
  }
}

:global(.tooltip)  {

  :global(.tooltip-inner) {
    background-color: #fff;
    color:#808080;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
    font-size: 12px;
    padding: 10px 10px;
  }
  :global(.tooltip-arrow) {
     display: none;
  }
}
.tooltips {
  cursor: pointer;
  position: relative;

  svg {
    color: #808080;
    width: 18px;
    height: 18px;
  }
}


.formBox {
  width: 100%;
  margin-bottom: 15px;

  label {
    font-size: 15px;
    margin-bottom: 5px;
  }

  :global(.react-tel-input) {
    font-family: 'Open Sans' !important;

    :global(.flag-dropdown) {
      padding: 0px;
      background: #f8f9f9;
      border: none;
      border-right: 0px;
      border-radius: 5px 0px 0px 5px !important;

      :global(.selected-flag) {
        padding: 0px 10px;
        width: 50px;
        border-radius: 5px 0px 0px 5px !important;
        border: 1px solid #ccc;
        border-right: 0px;

        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 50%;
          width: 0.5px;
          height: 20px;
          background: #c8bfbf;
          transform: translate(-50%, -50%);
        }
      }
    }
    :global(.form-control) {
      background-color: #f8f9f9;
      border: 1px solid #ccc;
      border-radius: 5px !important;
      height: 45px;
      padding: 0px 12px;
      padding-left: 60px;
      font-size: 14px;
      line-height: 45px;
      width: 100%;

      &:focus {
        border: 1px solid #ccc;
      }
    }
  }

  :global(.form-control) {
      background-color: #f8f9f9;
      border: 1px solid #ccc;
      padding: 10px 15px;
      font-size: 15px;
      border-radius: 5px;

      &:focus {
          box-shadow: none;
          border: 1px solid #fbbf15;
      }
      &.removeArrow {
        -moz-appearance: textfield;
        
        &::-webkit-outer-spin-butto,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
  }
  
  :global(.form-select) {
    background-color: #f8f9f9;
    border: 1px solid #ccc;
    padding: 10px 15px;
    font-size: 15px;
    border-radius: 5px;

    &:focus {
        box-shadow: none;
        border: 1px solid #fbbf15;
    }
    &.removeArrow {
      -moz-appearance: textfield;
      
      &::-webkit-outer-spin-butto,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
}
  .eyeIcon {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: #686868;
    cursor: pointer;
  }
  &.iconForm {
    position: relative;

    .icon {
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translate(0%, -50%);
      font-size: 20px;
      width: 40px;
      text-align: center;
      line-height: 41px;
      height: 100%;
      color: #3d4755;
    }

    :global(.form-control) {
      padding-left: 35px;
    }
  }

  .submitBtn {
    min-width: 150px;
      background: var(--Main);
      padding: 8px 15px;
      border-radius: 6px;
      font-weight: 500;
      font-size: 15px;
      border: 2px solid var(--Main);

      &:hover,&:active {
        background: var(--Main);
        border: 2px solid var(--Main);
      }

  }
  .fullWidth {
    width: 100%;
  }
  .submitBtn2 {
    background: #fff;
    min-width: 150px;
    border: 2px solid var(--Main);
    color:#222;
    padding: 8px 15px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 15px;

    &:hover,&:active {
      border: 2px solid var(--Main);
    }
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/


@media (max-width: 767px) {

  .pageWrapper {
    margin-left: 0px;
  
    .pageTitle {
      padding: 20px 20px;
      display:block;

      .pageTitleRow {
        display: block;
      }

        .rowTitleLeft {
          margin-bottom:20px;

          h5 {
            font-size: 30px;
            font-weight: 300;
            margin-bottom: 0px;
            color: #4D4D4D;

            .back {
                color: var(--Main2);
                text-decoration: none;
                margin-right: 20px;
            }
        }
      }
      
      .rowTitleRight {    
        overflow-x: scroll;
        display:flex;
        padding-bottom: 10px;

        :global(.btn) {
          min-width: auto;
          margin-left: 0px;
          margin-right: 10px;
        }
      }
      
    }
  
    .pageWrapperInside {
      padding:20px 20px;
  
    }
  }
  
  

}

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    
  }
  @media only screen and (max-width: 5520px) and (min-width: 768px) {
    
  }

  @media only screen and (min-width: 1200px) and (max-width: 1279px) {

  }


@media (min-width: 1200px) {


}

  /* =======================================================
  //////////////RESPONSIVE MEDIA QUERY END///////////////
  ======================================================= */