.bookingSection {
    position: relative;

    .bannersection {
        position: relative;

        img {
            width: 100%;
            height: 250px;
            object-fit: cover;
        }

        .bannerContent {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            text-align: center;
            margin-top: 20px;

            h2 {
                font-size: 40px;
                color: #fff;
                text-transform: uppercase;
                font-weight: 600;
                margin-bottom: 0px;
            }
        }
    }
}


.loginForm {

    .contentBody {
        h3{
            font-size: 26px;
            font-weight: 600;
            margin-bottom: 15px;
            color: #000;
        }

        h4{
            font-size: 23px;
            font-weight: 600;
            margin-bottom: 12px;
            color: #000;
        }
        p{
            color: #525252;
            margin-bottom: 12px;
            font-size: 15px;

        }
    }
}

.mainContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 35px;
    .stepCol {
        width: 20%;
        .steps {
            text-align: center;
            padding-bottom: 10px;
            font-weight: bold;
            font-size: 20px;
            color: #be8c03;
        }
        img {
            width: 100%;
            border-radius: 20px;
        }
        .labels {
            text-align: center;
            padding-top: 10px;
            font-weight: 500;
            font-size: 20px;
        }
    }
}





/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width:991px) {

    .bookingSection {

        .bannersection {

            .bannerContent {
                width: 92%;
            }
        }

    }
    .mainContainer {
        flex-direction: column;
        align-items: center;
        gap: 30px;
        .stepCol {
            width: 80%;
        }
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {}