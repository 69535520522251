.formShow {

    h5 {
        font-size: 16px;
        border-bottom: 1px solid #c5c5c5;
        padding: 6px 0px 12px 0px;
        color: black;
    }
    
}

.edit_icon{
    
    position: absolute;
    top: 15px;
    right: 8px;
    cursor: pointer;

}
