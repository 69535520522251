.bookingSection {
    position: relative;

    .bannersection {
        position: relative;

        img {
            width: 100%;
            height: 250px;
            object-fit: cover;
        }

        .bannerContent {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            text-align: center;
            margin-top: 20px;

            h2 {
                font-size: 40px;
                color: #fff;
                text-transform: uppercase;
                font-weight: 600;
                margin-bottom: 0px;
            }
        }
    }
}


.complete_profile {
    background: #f2f3f7;
    padding:80px 0px;


	.contentBody {
		background: #fff;
		border: 1px solid #eaeaea;
		min-height: 500px;

		.profileTitle {
			padding: 20px 25px;
			margin: 0;
			border-bottom: 1px solid #eaeaea;
            display: flex;
            justify-content: space-between;

			h5 {
				font-size: 23px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin: 0px;
			}
            .addNew {
                background: var(--Main);
                padding: 5px 15px;
                color: #101010;
                border: none;
            }
		}

		.contentInside {
			padding: 20px 20px

		}
	}
}

.addedCard {
    width: 100%;
    background: #fff;
    border: 2px solid #e8e8e8;
    padding: 13px 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
  
    &:focus {
      border: 2px solid var(--Main) !important;
    }
    &.active {
      border: 2px solid var(--Main);
    }
  
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: left;
  
      h6 {
        font-size: 18px;
        margin-bottom: 0px;
      }
      li {
        font-size: 14px;
        margin-top: 5px;
      }
    }
  
    &.radio {
      position: relative;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  
      &:hover input ~ .checkmark {
        background-color: #f3f1f1;
        border: 1px solid #d8d8d8;
      }
  
      input {
        opacity: 0;
        margin-right: 5px;
        position: absolute;
  
        &:checked ~ .checkmark {
          background-color: var(--Main);
          border: 1px solid var(--Main);
        }
        &:checked ~ .checkmark:after {
          display: block;
        }
      }
      .checkmark {
        position: absolute;
        top: 50%;
        right: 20px;
        height: 17px;
        width: 17px;
        background-color: #eee;
        border-radius: 50%;
        border: 1px solid #d8d8d8;
        transform: translate(-50%, -50%);
  
        &:after {
          content: "";
          position: absolute;
          display: none;
          left: 4px;
          top: 4px;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background: white;
        }
      }
  
      .deleteBtn {
        position: absolute;
        top: 50%;
        right: 0px;
        height: 30px;
        width: 30px;
        background-color: #bf4242;
        border-radius: 50%;
        border: 1px solid #bf4242;
        transform: translate(-50%, -50%);
        color: #fff;
      }
      .signupPoint {
        text-align: center;
        margin-top: 30px;
  
        p {
          color: #101010;
          margin-bottom: 0px;
  
          a {
            color: #fbbf15;
            text-decoration: underline;
          }
        }
        .resendBtnBottom {
          background-color: transparent;
          color: #fbbf15 !important;
          border: none !important;
          font-size: 14px;
          margin-right: -5px;
          padding: 0px;
          margin-top: 22px;
        }
      }
    }
  }
  .delet_icon {
    position: absolute;
    right: 10px;
  }
  .delet_card {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 999;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  



/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width:991px) {
    .bookingSection {

        .bannersection {

            .bannerContent {
                width: 92%;
            }
        }

    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {}