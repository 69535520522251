.editProfile {

    :global(.nav) {

        :global(.nav-item) {

            :global(.nav-link) {
                color: #333;

                &:global(.active) {
                    color:var(--Main);
                }
            }
        }
    }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {

  
}