.bookingSection {
    position: relative;

    .bannersection {
        position: relative;

        img {
            width: 100%;
            height: 250px;
            object-fit: cover;
        }

        .bannerContent {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            text-align: center;
            margin-top: 20px;

            h2 {
                font-size: 40px;
                color: #fff;
                text-transform: uppercase;
                font-weight: 600;
                margin-bottom: 0px;
            }
        }
    }
}


.loginForm {
    box-shadow: 0px 4px 40px 0px #70707030;
    background-color: #fff;
    border-radius: 15px;
    padding: 40px 40px;

    .contentBody {
        h3 {
            font-size: 23px;
            margin-bottom: 30px;
            font-weight: 600;
            text-align: center;
            color: #232322;
        }


        .forgotPassword {
            text-align: end;
            margin-top: 5px;

            a {
                font-size: 14px;
                color: #757575;
            }
        }

        .signupPoint {
            text-align: center;
            margin-top: 30px;

            p {
                color: #101010;
                margin-bottom: 0px;

                a {
                    color: #fbbf15;
                    text-decoration: underline;
                }
            }
        }

    }
}

:global(.PhoneInput) {
    width: 100%;
    padding: 12px 16px;
    font-size: 16px;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 6px;
  
    input {
      padding: 0px 10px !important;
      border: none;
  
      &:focus , &:focus-visible{
        border: none !important;
        border-color:none !important; 
        outline:none;
      }
    }
  }
  
  :global(.react-tel-input) {
  
    :global(.form-control) {
      padding-left: 70px !important;
      min-height: 50px;
      width: 100%;
    }
  
    :global(.selected-flag) {
      width: 60px;
    }
  }
  



/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width:991px) {

    .bookingSection {

        .bannersection {

            .bannerContent {
                width: 92%;
            }
        }

    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {}