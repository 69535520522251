.selectTaxi {
  border: none;
  background: #fff;
  display: flex;
  // min-width: 200px;
  width: 48%;
  border-radius: 10px;
  align-items: center;
  margin-bottom: 0px;
  padding: 5px 15px;
  position: relative;

  border: 2px solid #f5f5f5;
  

  // &:focus {
  //   border: 2px solid var(--Main);
  // }
  &.active {
    border: 2px solid var(--Main);
  }


  img {
    width: auto;
    height: 50px;
    object-fit: contain;
    margin-right: 15px;
  }
  .taxiTypeBody {
    width: 100%;
    text-align: left;

    h5 {
      margin-bottom: 0px;
      font-size: 18px;
    }

    .detailsUp {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      font-size: 12px;
      font-weight: 300;

      li {
        margin-right: 8px;
        margin-top: 3px;
      }
    }
  }
  .taxiPrice {
    h6 {
      margin: 0px;
    }
  }
}
.max{
  font-size: 12px;
 
    right: 11px;
  
    position: absolute;
    top: 3px;

}


@media (max-width: 767px) {
  .selectTaxi {
    margin-bottom: 10px;
    padding: 16px 15px;  
  
    img {
      width: auto;
      height: 50px;
    }
  }
}

